<template>
    <Section :title="$t('title.autonomy')" class="autonomy-section">
        <TextComponent   
            :topText="$t('technologyPage.unlockNewCommunication')"
            :mainText="$t('technologyPage.sayGoodbyeToControl')"
            :mainSpan="$t('technologyPage.experienceToCommunicate')"
            :mainTail="$t('technologyPage.withoutSurveillance')"
            :bottomText="$t('technologyPage.tryPlatformMessaging')"
            />
            
    </Section>
</template>

<script>
import { defineComponent } from 'vue';

import Section from '../../components/common/section.vue';
import TextComponent from '../../components/common/textComponent.vue';

export default defineComponent({
    name: 'AutonomySection',
    components: {
        TextComponent,
        Section,
    },

    computed: {
        localizedTabs() {
            return this.tabs.map(tab => ({
                ...tab,
                buttonText: this.$t(tab.buttonText),
                title: this.$t(`technologyPage.${tab.title}`),
                description: this.$t(`technologyPage.${tab.description}`),
                walletAdress: this.$t(`${tab.walletAdress}`),
            }));
        },
    },
});
</script>

<style>
@import '@/assets/style/technologyPage/autonomySection.scss';
</style>
