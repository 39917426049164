<template>
    <div class="component-content">
        <div class="top-text">
            <p> {{ topText }}</p>
        </div>
        <div class="main-text">
            {{ mainText }} <span class="main-span"> {{ mainSpan }}</span>{{ mainTail }}
        </div>
        <div class="bottom-text">
            <p> {{ bottomText }}</p>
        </div>
        <a :href="buttonLink"  target="_blank" v-if="button" class="active-button">
            <p>{{ button }}</p>
        </a>
    </div>
</template>

<script>
export default {
    name: 'TextComponent',
    props: {

        topText: {
            type: String,
            required: true
        },
        mainText: {
            type: String,
            required: true
        },
        mainSpan: {
            type: String,
            required: true
        },
        mainTail: {
            type: String,
            required: false
        },
        bottomText: {
            type: String,
            required: false
        },
        button: {
            type: String,
            required: false
        },
        buttonLink: {
            type: String,
            required: false
        }
    }
}
</script>
