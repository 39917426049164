<template>
    <Section class="associate-partner-section">
        <TextComponent :topText="$t('travelPage.sharedVision')" :mainText="$t('travelPage.offerSeamless')"
            :mainSpan="$t('travelPage.solutionBookingHotels')" :mainTail="$t('travelPage.networkTrustedProviders')"/>
        <!--   :bottomText="$t('travelPage.stayForJourney')" 
            :button="$t('travelPage.startBooking')"
            :buttonLink="'https://travel.atm.network'"  -->


        <div class="associate-partner-img">
            <img :src="imageSource">
        </div>

    </Section>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import Section from '../common/section.vue';
import TextComponent from '../common/textComponent.vue';
import useDarkMode from '@/darkMode';

export default defineComponent({
    name: 'AssociatePartnerSec',
    components: {
        TextComponent,
        Section,
    },
    setup() {
        const { isDark } = useDarkMode();
        



        const imageSource = computed(() => {
            return isDark.value
                ? 'images/travelPage/travelimg-dark.webp'
                : 'images/travelPage/travelimg.webp';
        });


        const screenWidth = ref(window.innerWidth);
        const isLargeScreen = computed(() => screenWidth.value > 900);

        const updateScreenWidth = () => {
            screenWidth.value = window.innerWidth;
        };

        onMounted(() => {
            window.addEventListener('resize', updateScreenWidth);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', updateScreenWidth);
        });

        return {
            imageSource,
            isLargeScreen,
        };
    },
});
</script>

<style scoped>
@import '@/assets/style/travelPage/AssociatePartnerSec.scss';
</style>
