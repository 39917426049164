<template>
    <Section :title="$t('travelPage.hotelDeals')" class="hotel-deals-section">

       
   
        <div class="hotel-cards-container">
            <a
                class="hotel-card"
                v-for="(hotel, index) in hotelList"
                :key="index"
                :href="hotel.link"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img :src="hotel.image" alt="Hotel Image" class="hotel-card-image" />
                <div class="hotel-card-content">
                    <div class="stars">
                        <img src="../../assets/images/travelPage/stars.svg" alt="Stars" />
                    </div>
                    <div class="hotel-card-rating">
                        <span>{{ hotel.rating }}</span>
                        <span>/5.0</span>
                    </div>
                </div>
                <h3 class="hotel-card-title">{{ hotel.name }}</h3>
                <p class="hotel-card-price">From <span class="price">{{ hotel.price }}</span></p>
            </a>
        </div>

    </Section>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import Section from '../common/section.vue';

export default defineComponent({
    name: 'HotelDeals',
    components: {
        Section,
    },
    setup() {
        const { t } = useI18n();


        const hotelList = ref([
            {
                link: "https://www.trip.com/w/klWEaXRzYZ1",
                image: "images/travelPage/hotel_image.webp",
                rating: "4.1",
                name: "Metropole Bangkok",
                price: "US$43.00",
            },
            {
               link:'https://www.trip.com/w/O0KfCV00ZZ1',
                image: "images/travelPage/hotel_image1.webp",
                rating: "4.0",
                name: "Ambassador Hotel Bangkok",
                price: "US$50.00",
            },
            {
                link:'https://www.trip.com/w/sHpWw6C0ZZ1',
                image: "images/travelPage/hotel_image2.webp",
                rating: "4.1",
                name: "Twin Towers Hotel",
                price: "US$56.00",
            },
            {
                link: "https://www.trip.com/w/69NUaRyzYZ1",
                image: "images/travelPage/hotel_image3.webp",
                rating: "4.4",
                name: "Miracle Grand Convention Hotel",
                price: "US$46.00",
            },
        ]);

   
        const screenWidth = ref(window.innerWidth);
        const isLargeScreen = computed(() => screenWidth.value > 900);

        const updateScreenWidth = () => {
            screenWidth.value = window.innerWidth;
        };

        onMounted(() => {
            window.addEventListener('resize', updateScreenWidth);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', updateScreenWidth);
        });

        return {
            hotelList,
            isLargeScreen,
        };
    },
});
</script>


<style scoped>
@import '@/assets/style/travelPage/hotelDeals.scss';
</style>
