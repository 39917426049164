<template>
    <div class="travel-page">
        <div class="first-screen-image">
            <img src="../assets/images/travelPage/atmtravel-mainimg.webp" alt="">
        </div>

        <div class="hotel-deals-text">
            <p v-html="$t('travelPage.optimizeJourneys')"></p>
        </div>

        <Section :title="$t('travelPage.flights')" class="travel-section-wrapper">
            <div class="flights-img">
                <img src="../assets/images/travelPage/imgbg-flight.webp" alt="imgbg-flight">
            </div>

            <div class="travel-content">
                <div class="text">
                    <div class="large-body-text">
                        <p>{{ $t('travelPage.flightThroughATM') }}</p>
                    </div>
                    <div class="text-logo-wrapper">
                        <div class="travel-logo">
                            <img src="../assets/images/logo/atmtravellogo.png" alt="atmtravellogo">
                        </div>
                        <div class="large-body-text">
                            <p>{{ $t('travelPage.with') }}</p>
                        </div>
                        <a href="https://www.trip.com/t/dfVk03pnkZ1" target="_blank" class="trip-logo">
                            <img src="../assets/images/logo/trip.svg" alt="trip-logo">
                        </a>
                    </div>
                </div>
                <div class="desc">
                    <div class="body-text">
                        <p>{{ $t('travelPage.effortlessBooking') }}</p>
                    </div>

                </div>
            </div>

            <a href="https://www.trip.com/t/dfVk03pnkZ1" target="_blank" class="hotels-content">
                <div class="content-text-wrapper">
                    <div class="bold-body-text">
                        <p>{{ $t('travelPage.saveUpTo25') }}</p>
                    </div>
                    <div class="large-body-text">
                        <p>{{ $t('travelPage.yourNextFlight') }}</p>
                    </div>
                </div>
                <div class="active-button">
                    <p>{{ $t('travelPage.bookFlight') }}</p>
                </div>

            </a href="https://www.trip.com/t/dfVk03pnkZ1">
            <div class="flights-content-text">
                <p v-html="$t('travelPage.bookHolidayWithLuca')"></p>
            </div>
        </Section>





        <Section :title="$t('travelPage.hotels')" class="travel-section-wrapper">
            <div class="hotels-img">
                <img src="../assets/images/travelPage/imgbg-hotel.webp" alt="imgbg-hotel">
            </div>

            <div class="travel-content">
                <div class="text">
                    <div class="large-body-text">
                        <p>{{ $t('travelPage.bookHotelThroughATM') }}</p>
                    </div>

                    <div class="travel-logo">
                        <img src="../assets/images/logo/atmtravellogo.png" alt="atmtravellogo">
                    </div>
                </div>
                <div class="desc">
                    <div class="body-text">
                        <p>{{ $t('travelPage.hotelExclusives') }}</p>
                    </div>

                </div>
            </div>

            <a href="https://travel.atm.network" target="_blank" class="hotels-content">
                <div class="content-text-wrapper">
                    <div class="bold-body-text">
                        <p>{{ $t('travelPage.saveUpTo') }}</p>
                    </div>
                    <div class="large-body-text">
                        <p>{{ $t('travelPage.yourNextHotelBooking') }}</p>
                    </div>
                </div>
                <div class="active-button">
                    <p>{{ $t('travelPage.bookHotel') }}</p>
                </div>

            </a>
        </Section>





        <!-- <HotelDeals /> -->


        <AssociatePartnerSec />
        <DownloadSection />
    </div>
</template>
<script>


import AssociatePartnerSec from '../components/travelPage/AssociatePartnerSec.vue';
import DownloadSection from '../components/homePage/downloadSection.vue';
import HotelDeals from '../components/travelPage/hotelDeals.vue';
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import Section from '../components/common/section.vue';
import { useI18n } from 'vue-i18n';
export default {
    name: 'TravelPage',
    components: {
        AssociatePartnerSec,
        DownloadSection,
        HotelDeals,
        Section
    },
    setup() {
        const { t } = useI18n();
        const features = [
            { text: "findFast" },
            { text: "bookWithEase" },
            { text: "saveBig" },
        ];
        const textList = computed(() => {
            return features.map(item => ({
                ...item,
                text: t(`travelPage.${item.text}`),
            }));
        });

        const screenWidth = ref(window.innerWidth);
        const isLargeScreen = computed(() => screenWidth.value > 900);

        const updateScreenWidth = () => {
            screenWidth.value = window.innerWidth;
        };

        onMounted(() => {
            window.addEventListener('resize', updateScreenWidth);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', updateScreenWidth);
        });
        return {
            isLargeScreen,
            textList
        };
    }
}
</script>

<style scoped>
@import '@/assets/style/travelPage/travelPage.scss';
</style>
