<template>
    <div class="news-section">
        <div class="news-section-top">
            <div class="title-buttons-wrapper">
                <div class="section-title">
                    <!-- latest news {{ this.loader}} -->
                    {{ $t('latestNews') }}
                </div>
                <controlButtons :disableLeft="disableLeft" :disableRight="disableRight" @switch-element="switchElement"
                    v-show="false" />
            </div>
            <div class="section-top-count" style="display: none;">
                <transition name="slide" mode="out-in">
                    <div class="couter-wrapper">
                        <NewsCaunter v-show="currentIndex === index" v-for="(counter, index) in localizedCardDataList"
                            :key="index" :title="counter.title" :endDate="counter.endDate" :link="counter.link"
                            :displayText="getDisplayText(counter)" />
                    </div>
                </transition>
            </div>
        </div>

        <div class="news-slider">
            <newsSlider :slides="newsArray"></newsSlider>
        </div>
    </div>
</template>
<script>
import NewsCaunter from './newsCaunter.vue';
import controlButtons from '../homePage/controlButtons.vue';
import newsSlider from '../homePage/newsSlider.vue';
import { fetchNewsList, showDefaultImageIfEmpty } from '@/api/api';

export default {
    name: 'NewsSection',

    data() {
        return {
            loader: false,
            pageSize: 3,
            pageIndex: 1,

            counterData: [
                { title: 'deflationMechanism', endDate: '2024-07-24', link: { name: 'news', params: { id: '71' } } },
            ],


            currentIndex: 0,
            disableLeft: true,
            disableRight: false,

            newsArray: [
                // {
                //     "coverImg": "https://atmspace.blob.core.windows.net/cssg/CSSG0169d26c0384b0ad214a608f6bd34010.png",
                //     "title": "The largest Crypto Expo in southeast Asia is coming to Thailand from the 06/10/2022 to 09/10/2022."
                // },
                // {
                //     "coverImg": "https://monkeyspace.blob.core.windows.net/cssg/CSSG274d4b46857739760c71e229ad1902e7.png",
                //     "title": "test0001"
                // },
                // {
                //     "coverImg": "https://monkeyspace.blob.core.windows.net/cssg/CSSG59bd0eccd25fa0915671e2cafbef39d6.png",
                //     "title": "232"
                // },

            ],

        };

    },
    components: {
        NewsCaunter,
        controlButtons,
        newsSlider,
    },
    computed: {
        localizedCardDataList() {
            return this.counterData.map(item => ({
                ...item,
                title: this.$t(`homePage.${item.title}`),
            }));
        },
    },
    mounted() {
        this.fetchNewsData();
    },
    methods: {

        switchElement(direction) {


            if (direction === 'left') {
                this.prevElement();
            } else {
                this.nextElement();
            }
        },
        prevElement() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            }
            this.disableLeft = this.currentIndex === 0;
            this.disableRight = false;
        },
        nextElement() {
            if (this.currentIndex < this.counterData.length - 1) {
                this.currentIndex++;
            }

            this.disableRight = this.currentIndex === this.counterData.length - 1;
            this.disableLeft = false;
        },


        async fetchNewsData() {
            try {
                this.loader = true;
                const response = await fetchNewsList(this.pageIndex, this.pageSize);

                if (response.success) {
                    const newsList = response.data.newsList;
                    console.log(newsList)
                    const formattedNewsArray = newsList.map(news => ({
                        coverImg: news.coverImg || 'images/junkNews.png',
                        title: news.title || '',
                        content: news.content || '',
                        id: news.id || ''
                    }));

                    this.newsArray = formattedNewsArray;

                    if (response.data.newsList) {
                        response.data.newsList.forEach(news => {
                            showDefaultImageIfEmpty(news);
                        });
                    }
                } else {
                    console.error('API request failed');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loader = false;
            }
            this.showDefaultImageIfEmptyArray()
        },


        showDefaultImageIfEmptyArray() {
            if (this.newsArray.length === 0) {

                this.newsArray.push({
                    coverImg: 'images/junkNews.png',
                    title: this.$t(`junkTitle`),
                    content: this.$t(`junkContent`),
                });
            }
        },
        getDisplayText(counter) {
            if (counter.endDate < new Date().toISOString()) {
                return this.$t('homePage.deflationMechanismEnabled');
            }
            return counter.title;
        }
    }

}
</script>
<style scoped>
@import '../../assets/style/homePageStyle/newsSection.scss';
</style>
